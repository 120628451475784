@keyframes hideSubmitButton {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.settingsForm {
    max-width: 480px;
}

.settingsFormSubmitButtonVisible {
    opacity: 1;
}

.settingsFormSubmitButtonHidden button {
    pointer-events: none;
}

.settingsFormSubmitButtonHidden {
    animation: hideSubmitButton 500ms forwards;
}

.submitChangesText,
.submitChangesText > span,
.settingsButton span + div {
    margin-left: 0.5rem;
    display: none;
}

.settingsButton svg {
    margin-right: 0 !important;
}

@media (min-width: 590px) {
    .submitChangesText,
    .settingsButton span + div {
        display: inline-block;
    }
}

@media (min-width: 800px) {
    .submitChangesText > span {
        display: inline-block;
    }
}

@media (min-width: 640px) and (max-width: 675px) {
    .submitChangesText,
    .submitChangesText > span {
        display: none;
    }

    .settingsButton span + div {
        display: none;
    }
}
