@media (max-width: 600px) {
    .pt-status-bar {
        padding-top: 5rem!important;        
    }
}

@media (max-width: 500px) {
    .router-container {
        padding-left: 0!important;        
        padding-right: 0!important;        
    }
}