.react-select__option .multi-select-option > div {
    margin-right: 10px;
}

.react-select-bottom .react-select__option:last-child {
    border-radius: 0 0 3px 3px;
}

.react-select-top .react-select__option:first-child {
    border-radius: 3px 3px 0 0;
}