.weather-cards-wrapper:not(.emty-weather-cards-wrapper) {
    padding: 5px;
}

.weather-cards-wrapper .weather-card {
    margin: 5px;
    flex: 1 0 47%;
    min-height: 200px;
    max-width: calc(50% - 10px);
}

@media (min-width: 600px) and (max-width: 899px) {
    .weather-cards-wrapper .weather-card {
        flex: 1 0 30%;
        max-width: calc(33.3% - 10px);
    }
}

@media (min-width: 1050px) {
    .weather-cards-wrapper .weather-card {
        flex: 1 0 30%;
        max-width: calc(33.3% - 10px);
    }
}

@media (min-width: 1350px) {
    .weather-cards-wrapper .weather-card {
        flex: 1 0 21%;
        max-width: calc(25% - 10px);
    }
}

@media (min-width: 1650px) {
    .weather-cards-wrapper .weather-card {
        flex: 1 0 19%;
        max-width: calc(20% - 10px);
    }
}
