
@media (max-width: 1280px) {
    .dateTime::placeholder{
        font-size: 0.9rem!important;
    }
}

@media (max-width: 1024px) {
    .dateTime::placeholder{
        font-size: 0.85rem!important;
    }
}

@media (max-width: 768px) {
    .dateTime::placeholder{
        font-size: 0.80rem!important;
    }
}