.action-bar-wrapper {
	display: grid;
	grid-gap: 10px;
}

.filters-subwrapper {
	display: grid;
	grid-template-columns: 15rem 15rem 165px;
	grid-gap: 10px;
	justify-items: start;
}

@media (min-width: 1280px) {
	p.action-bar-filter-label {
		position: absolute;
		top: -20px;
	}

	.action-bar-wrapper {
		grid-template-columns: 16rem auto 95px;
		grid-template-areas: 
		"search filters actions";
	}

	.action-bar-wrapper .action-bar-filter {
		display: none!important;
		position: absolute!important; /*it is a hack for avoiding grid stretching*/
	}

	.action-bar-wrapper .filters-wrapper {
		max-height: 100vh!important;
	}

	.action-bar-wrapper .filters-subwrapper {
		opacity: 1!important;  
	}
}

@media (max-width: 1279px) {
	.action-bar-search {
		grid-area: search;
	}
	.action-bar-filters {
		grid-area: filters;
	}
	.action-bar-actions {
		grid-area: actions;
	}

	.action-bar-wrapper {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 14rem auto 195px;
		grid-template-areas: 
		"search search actions"
		"filters filters filters";
	}

	.filters-subwrapper {
		grid-template-columns: 14rem 14rem 165px;
	}
}


@media (max-width: 1024px) {
	.action-bar-wrapper {
		grid-template-columns: 13rem auto 195px;
	}

	.filters-subwrapper {
		grid-template-columns: 13rem 13rem 165px;
	}
}

@media (max-width: 830px) {
	.filters-subwrapper {
		grid-template-columns: 1fr;
	}

	.filters-subwrapper input,  
	.filters-subwrapper .action-bar-filter-label + div {
		width: 100%!important; 
	}

	.filters-subwrapper .date-time input + div {
		width: 250px!important;
		z-index: 31;
	}
}

@media (max-width: 640px) {
	.action-bar-wrapper {
		grid-template-columns: 1fr;
	}
}