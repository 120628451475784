@media (max-width: 600px) {
    .navigation {
        top: 5rem!important;        
    }
}

@media (min-width: 601px) {
    .navigation {
       border-top: none!important;
    }
}