.home-page-wrapper .pressure-area {
    grid-area: pressure-area;
}

.home-page-wrapper .wind-rose {
    grid-area: first-rose;
}

.home-page-wrapper .wind-rose + .wind-rose {
    grid-area: second-rose;
}

.home-page-wrapper.empty-rose .wind-rose {
    grid-area: first-rose;
}

.home-page-wrapper .rvr-area {
    grid-area: rvr-area;
}

.home-page-wrapper .cloud-area {
    grid-area: cloud-area;
}

.home-page-wrapper .weather-area {
    grid-area: weather-area;
}

.home-page-wrapper .gas-area {
    grid-area: gas-area;
}

.home-page-wrapper .create-report-area {
    grid-area: create-report-area;
}

.home-page-wrapper .check-report-area {
    grid-area: check-report-area;
}

.home-page-wrapper {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 10px;
}

.home-page-wrapper .wind-rose.active-rose .basic-window-container {
    box-shadow: 0px 0px 3px 0 #66be6f, 0 0px 5px 0px #66be6f;
    border: none !important;
}

.home-page-wrapper.non-empty-rose .name-cell,
.home-page-wrapper .name-cell {
    padding-right: 0 !important;
}

.home-page-wrapper .basic-window-container-top-bar {
    height: 40px;
    display: flex !important;
    align-items: center;
}

.home-page-wrapper .name-cell {
    height: 40px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

@media (max-width: 600px) {
    .home-page-wrapper .gas-table .tuple-cell {
        display: flex;
    }

    .home-page-wrapper .gas-table .header-cell,
    .home-page-wrapper .gas-table .cell {
        border-top: 0;
    }
}

@media (min-width: 700px) {
    .home-page-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 850px) {
    .home-page-wrapper.empty-rose .wind-rose {
        margin: 0 150px;
    }
}

@media (min-width: 601px) and (max-width: 1023px) {
    .home-page-wrapper .gas-table tr .tuple-cell:first-child {
        width: 33%;
    }
}

@media (min-width: 601px) and (max-width: 1100px) {
    .home-page-wrapper .gas-table td + td div.header-cell {
        border-left: 0;
    }
}

@media (min-width: 1100px) {
    .home-page-wrapper {
        grid-template-columns: 275px auto 275px;
    }

    .home-page-wrapper.empty-rose .wind-rose {
        margin: 0;
    }
}

@media (min-width: 1100px) and (max-width: 1150px) {
    .home-page-wrapper {
        grid-template-columns: 250px auto 250px;
    }

    .home-page-wrapper .cell,
    .home-page-wrapper .header-cell {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        font-size: 0.85rem !important;
        line-height: 1.25rem !important;
    }

    .home-page-wrapper .header-cell {
        font-size: 0.9rem !important;
        line-height: 1.25rem !important;
    }
}

@media (min-width: 1150px) and (max-width: 1250px) {
    .home-page-wrapper .cell,
    .home-page-wrapper .header-cell {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;

        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
    }

    .home-page-wrapper .header-cell {
        font-size: 1rem !important;
        line-height: 1.25rem !important;
    }
}

@media (min-width: 1100px) and (max-width: 1400px) {
    .home-page-wrapper.non-empty-rose .gas-table .tuple-cell {
        display: flex;
    }

    .home-page-wrapper .gas-table .header-cell,
    .home-page-wrapper .gas-table .cell {
        border-top: 0;
    }
}

@media (min-width: 1100px) and (max-width: 1250px) {
    .home-page-wrapper:not(.empty-rose) .met-report-table td {
        font-size: 0.85rem !important;
    }

    .home-page-wrapper:not(.empty-rose) .met-report-table th {
        font-size: 0.65rem !important;
    }
}

@media (min-width: 1100px) and (max-width: 1350px) {
    .home-page-wrapper {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}

@media (min-width: 1400px) {
    .home-page-wrapper .gas-table tr .tuple-cell:first-child {
        width: 33%;
    }

    .home-page-wrapper .gas-table td + td div.header-cell {
        border-left: 0;
    }
}

@media (min-width: 1300px) {
    .home-page-wrapper.empty-rose {
        grid-template-columns: 400px auto 400px;
    }
}

@media (min-width: 1600px) {
    .home-page-wrapper {
        grid-template-columns: 400px auto 400px;
    }

    .home-page-wrapper.empty-rose {
        grid-template-columns: 450px auto 450px;
    }
}

@media (min-width: 1100px) and (max-width: 1250px) {
    .home-page-wrapper:not(.empty-rose) .name-cell {
        text-align: center !important;
        padding: 0 !important;
    }

    .home-page-wrapper:not(.empty-rose) .name-cell span:not(.svg) {
        display: none;
    }
}

@media (max-width: 550px) {
    .home-page-wrapper .name-cell {
        text-align: center !important;
        padding: 0 !important;
    }

    .home-page-wrapper .name-cell span:not(.svg) {
        display: none;
    }
}
