@media (max-width: 640px) {
    .appName {
        display: none;
    }

    .navigation {
        height: 4rem !important;
    }
}

@media (max-width: 600px) {
    .appName {
        display: none;
    }

    .navigation {
        flex-flow: column;
        height: auto!important;
    }

    .appLogo {
        width: 100%;
        padding: 0.25rem 0 0 0;
    }

    .appLogo > span {
        margin: auto!important;
    }
}

@media (min-width: 540px) and (max-width: 600px) {
    .appLogo button {
        position: relative;
        top: 20px;
    }
}