@font-face {
    font-family: 'Arimo';
    src: url('./fonts/Arimo-VariableFont_wght.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/RobotoMono-VariableFont_wght.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}

/* This style fixes Bitwarden third party extention bug */
/* https://github.com/bitwarden/browser/issues/2231 */
/* Start */
#bit-notification-bar-spacer {
    height: 0px !important;
}
/* Finish */

.fc {
    --fc-today-bg-color: none;
    --fc-border-color: theme('colors.awos.gray.23');
}
.dark .fc {
    --fc-border-color: theme('colors.awos.black.13');
}

* {
    font-family: 'Arimo', sans-serif;
}

abbr[title] {
    text-decoration: none;
}

.svg path {
    fill: currentColor;
}

.placeholder-font-normal::placeholder {
    font-weight: 400;
}

.datePicker {
    width: 100%;
    overflow: hidden !important;
    border: none !important;
    border-radius: 0px !important;
    font-size: 1rem !important;
    background-color: rgba(255, 255, 255, 0) !important;
    margin-bottom: 0;
    border-bottom: 1px solid #0000002e !important;
}

.react-datepicker__header {
    align-self: center !important;
    width: 100% !important;
    background-color: #f5f4f4 !important;
    border-color: #00000026 !important;
    border-radius: 0.25rem 0.25rem 0rem 0rem !important;
}

.dark .react-datepicker__header {
    background-color: #1e2025 !important;
}

.react-datepicker__navigation {
    width: 2rem !important;
    height: 2rem !important;
    border: none !important;
    overflow: visible !important;
    padding: 0 !important;
    top: 0.2rem !important;
}

.react-datepicker__navigation--previous {
    left: 0.2rem !important;
    padding-right: 3px !important;
    padding-top: 2px !important;
}

.react-datepicker__navigation--next {
    right: 0.2rem !important;
    padding-left: 3px !important;
    padding-top: 2px !important;
}

.react-datepicker__navigation-icon--previous::before {
    border: none !important;
}

.react-datepicker__navigation-icon--next::before {
    border: none !important;
}

.react-datepicker__month-container {
    width: 100% !important;
}

.react-datepicker__current-month {
    color: #123a64 !important;
}

.dark .react-datepicker__current-month {
    color: #dce2f2 !important;
}

.react-datepicker__month:last-child {
    margin-bottom: 0rem !important;
}

.react-datepicker__week {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    background-color: #123a64 !important;
    border-radius: 50% !important;
    color: white !important;
}

.react-datepicker__day--selected:hover {
    transition: color, background-color;
    transition-duration: 500ms;
}

.react-datepicker__day--keyboard-selected {
    background: none !important;
    border: none;
    color: #123a64 !important;
}

.dark .react-datepicker__day--selected {
    color: #1e2025 !important;
    background-color: #dce2f2 !important;
}

.react-datepicker__day:hover {
    border-radius: 50% !important;
}

.dark .react-datepicker__day--keyboard-selected:hover {
    color: #dce2f2 !important;
}

#root .fc .fc-col-header-cell-cushion {
    display: block;
    padding: 9.75px 10px;
}

#root th.fc-timegrid-axis {
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    border-color: var(--fc-border-color, #ddd);
}

#root .fc .fc-timegrid-slot-label {
    position: relative;
    bottom: 50%;
    padding: 0px 12px 0px 4px;
}

#root .fc .fc-timegrid-slot-label:after {
    position: absolute;
    content: '';
    top: 49%;
    right: 0%;
    width: 8px;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: var(--fc-border-color, #ddd);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#root .fc .fc-scroller-harness {
    overflow: visible;
}

#root div.fc-scroller-liquid-absolute {
    overflow: visible !important;
}

input[type='number'].appearance-none::-webkit-inner-spin-button,
input[type='number'].appearance-none::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.dark input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #15151a inset !important;
    -webkit-text-fill-color: #dce2f2;
}

.flex-full-height {
    display: flex;
    flex: 1;
    flex-flow: column;
}
