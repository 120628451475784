.dark .awos-rc-collapse.rc-collapse, .dark .awos-rc-collapse.rc-collapse > .rc-collapse-item {
    border: none;
} 

.awos-rc-collapse.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
    margin-right: 0;
}

.awos-rc-collapse .dropdown-icon {
    transition: transform 500ms ;
} 

.awos-rc-collapse .rc-collapse-item-active .dropdown-icon  {
    transform: rotate(180deg);
}

.awos-rc-collapse .rc-collapse-content {
    background: transparent;
    padding: 0;
}

.awos-rc-collapse .rc-collapse-content-box {
    margin: 0;
}

.awos-rc-collapse *:not(.content-hidden) + .collapse-arrow {
    border-left: 1px solid;
}

.awos-rc-collapse .rc-collapse-content.rc-collapse-content-active:not(.rc-collapse-motion) {
    overflow: initial;
}
