.small-station-home-page-grid .wind-rose {
    grid-area: rose;
    max-width: 400px;
    margin: 0 auto;
}

.small-station-home-page-grid .weather-area {
    grid-area: weather-area;
}

.small-station-home-page-grid .statistic-area {
    grid-area: statistic-area;
}

.small-station-home-page-grid {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 10px;
    grid-template-areas:
        'rose'
        'weather-area'
        'statistic-area' !important;
}

.small-station-home-page-grid .wind-rose.active-rose .basic-window-container {
    box-shadow: 0px 0px 3px 0 #66be6f, 0 0px 5px 0px #66be6f;
    border: none !important;
}

.small-station-home-page-grid .name-cell {
    padding-right: 0 !important;
}

.small-station-home-page-grid .basic-window-container-top-bar {
    height: 40px;
    display: flex !important;
    align-items: center;
}

.small-station-home-page-grid .name-cell {
    height: 40px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

@media (min-width: 900px) {
    .small-station-home-page-grid {
        grid-template-columns: 300px auto;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            'rose weather-area'
            'rose statistic-area' !important;
    }
}

@media (min-width: 900px) and (max-width: 1300px) {
    .small-station-home-page-grid {
        grid-template-columns: 300px auto;
        grid-template-areas:
            'rose weather-area'
            'statistic-area statistic-area' !important;
    }
}

@media (min-width: 1300px) {
    .small-station-home-page-grid {
        grid-template-columns: 400px auto;
    }

    .small-station-home-page {
        justify-content: space-between;
    }
}
